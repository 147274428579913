.picker-panel-container {
  overflow: hidden;
  vertical-align: top;
  background: #fff;
  border-radius: 2px;
  -webkit-box-shadow: 0 3px 6px -4px rgba(0,0,0,.12), 0 6px 16px 0 rgba(0,0,0,.08), 0 9px 28px 8px rgba(0,0,0,.05);
  box-shadow: 0 3px 6px -4px rgba(0,0,0,.12), 0 6px 16px 0 rgba(0,0,0,.08), 0 9px 28px 8px rgba(0,0,0,.05);
  -webkit-transition: margin .3s;
  transition: margin .3s;
  border-radius: 0.5rem;
}

.picker-panel-container .picker-panel {
  vertical-align: top;
  background: 0 0;
  border-width: 0 0 1px;
  border-radius: 0;
}

.picker-panel {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  text-align: center;
  background: #fff;
  border: 1px solid #f0f0f0;
  border-radius: 2px;
  outline: none;
}

.picker-dropdown .picker-panel>.picker-time-panel {
  padding-top: 4px;
}
.picker-time-panel {
  width: auto;
  min-width: auto;
}
.picker-date-panel, .picker-decade-panel, .picker-month-panel, .picker-quarter-panel, .picker-time-panel, .picker-week-panel, .picker-year-panel {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

.picker-time-panel-column>li.picker-time-panel-cell .picker-time-panel-cell-inner {
  display: block;
  width: 100%;
  height: 28px;
  margin: 0;
  padding: 0 0 0 14px;
  color: rgba(0,0,0,.85);
  line-height: 28px;
  border-radius: 0;
  cursor: pointer;
  -webkit-transition: background .3s;
  transition: background .3s;
}
.picker-time-panel .picker-content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 1;
  -ms-flex: auto;
  flex: auto;
  height: 224px;
}

.picker-time-panel-column {
  position: relative;
  -webkit-box-flex: 1;
  -ms-flex: 1 0 auto;
  flex: 1 0 auto;
  width: 56px;
  margin: 0;
  padding: 0;
  overflow-y: scroll;
  text-align: left;
  list-style: none;
  -webkit-transition: background .3s;
  transition: background .3s;
}

.picker-footer {
  width: -webkit-min-content;
  width: -moz-min-content;
  width: min-content;
  min-width: 100%;
  line-height: 38px;
  text-align: center;
  border-bottom: 1px solid transparent;
}

.picker-content {
  width: 100%;
  table-layout: fixed;
  border-collapse: collapse;
}
