.tab-list {
    border-bottom: .125rem solid #E02D39;
    padding-left: 0;
    display: flex;
    justify-content: space-between;
  }
  
  .tab-list-item {
    cursor: pointer;
    width: 100%;
    height: 2.75rem;
    background-color: transparent;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: .5rem;
    gap: .5rem;
  }
  
  .tab-list-active {
    width: 100%;
    height: 2.75rem;
    background-color: #E02D39;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: .5rem;
    gap: .5rem;
  }

  .tab-list-item-label{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 1.125rem;
    line-height: 1.25rem;
    @media (max-width: 576px)
    {
    font-size: .8125rem !important;
    }
    display: flex;
    align-items: center;
    text-align: center;
    color: #E02D39;
  }

  .tab-list-item-label-active{
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 600;
      font-size: 1.125rem;
      line-height: 1.75rem;
      /* identical to box height, or 156% */
      @media (max-width: 576px)
      {
      font-size: .8125rem !important;
      line-height: 1.25rem;

      }
      display: flex;
      align-items: center;
      text-align: center;

      /* Background */

      color: #FAFAFA;
  }