//
// Notes
//


// Variables
$timeline-3-media-size: 50px;
$timeline-3-border-width: 2px;

.timeline.timeline-3 {
    .timeline-items {
        margin: 0;
        padding: 0;

        .timeline-item {
            margin-left: $timeline-3-media-size/2;
            border-left: $timeline-3-border-width solid $border-color;
            padding: 0 0 20px $timeline-3-media-size;
            position:relative;

            .timeline-media {
                position: absolute;
                top: 0;
                left: -(($timeline-3-media-size + $timeline-3-border-width)/2);
                border: 2px solid $border-color;
                border-radius: 100%;
                width: $timeline-3-media-size;
                height: $timeline-3-media-size;
                display: flex;
                justify-content: center;
                align-items: center;
                background-color: $white;
                line-height: 0;

                i {
                    font-size: 1.4rem;
                }

                .svg-icon {
                    @include svg-icon-size(24px);
                }

                img {
                    max-width: $timeline-3-media-size - $timeline-3-border-width;
                    max-height: $timeline-3-media-size - $timeline-3-border-width;
                    border-radius: 100%;
                }
            }

            .timeline-content {
                @include border-radius($border-radius-lg);
                position:relative;
                background-color: $gray-100;
                padding: 0.75rem 1.5rem;

                &:before {
					position: absolute;
					content: '';
					width: 0;
					height: 0;
					top: 10px;
					left: -($timeline-3-media-size/2);
					border-right: solid 10px $gray-100;
					border-bottom: solid 17px transparent;
					border-left: solid 17px transparent;
					border-top: solid 17px transparent;
				}
            }

            &:last-child {
                border-left-color: transparent;
                padding-bottom: 0;
            }
        }
    }
}


// Tablet & Mobile Modes
@include media-breakpoint-down(md) {
    .timeline.timeline-3 {

    }
}
