//
// Header
//

// Desktop Mode
@include media-breakpoint-up(lg) {
  .header {
    display: flex;
    justify-content: space-between;
    height: get($header-config, desktop, default, height);
    position: relative;
    padding: 24px;
    z-index: 2;

    // Fixed Header Mode
    &.header-fixed {
      height: get($header-config, desktop, fixed, height);
      position: fixed;
      top: 32px;
      right: 54px;
      left: 0;
      z-index: get($header-config, desktop, fixed, zindex);
    }

    // Aside Enabled Mode
    .aside-enabled & {
      &.header-fixed {
        left: get($aside-config, base, width) + 48px;
      }
    }

    // Fixed Header & Minimized Aside & Minimized Aside Hover Modes
    .header-fixed.aside-minimize-hover &,
    .header-fixed.aside-minimize & {
      left: get($aside-config, base, minimized-width) + 48px ;
    }

    // Fixed Header & Fixed Subheader Modes
    .header-fixed.subheader-fixed & {
      box-shadow: none !important;
    }
  }

  body[dir*="rtl"] {
    // Aside Enabled Mode
    &.aside-enabled .header.header-fixed {
      right: get($aside-config, base, width) + 48px;
      left: 54px;
    }


    // Fixed Header & Minimized Aside & Minimized Aside Hover Modes
    &.header-fixed.aside-minimize-hover .header,
    &.header-fixed.aside-minimize .header {
      right: get($aside-config, base, minimized-width) + 48px;
      left: 54px;
    }

  }
}
