@each $name, $colors in $component-colors {
  $base-color: map-get($colors, base);
  $font-color: map-get($colors, font);

  .bg-#{$name} {
    background: $base-color !important;
  }

  .font-#{$name} {
    color: $base-color !important;
  }

  .bg-font-#{$name} {
    color: $font-color !important;
  }

  .border-#{$name} {
    border-color: $base-color !important;
  }

  /////////////////////
  // pseudo elements //
  /////////////////////

  .bg-hover-#{$name}:hover {
    background: $base-color !important;
  }

  .font-hover-#{$name}:hover {
    background: $base-color !important;
  }

  .border-hover-#{$name}:hover {
    border-color: $base-color !important;
  }

  .bg-before-#{$name}:before,
  .bg-after-#{$name}:after {
    background: $base-color !important;
  }

  .border-before-#{$name}:before,
  .border-after-#{$name}:after {
    border-color: $base-color !important;
  }
}

@each $name, $colors in $component-colors {
  $base-color: map-get($colors, base);
  $font-color: map-get($colors, font);

  .border-top-#{$name} {
    border-top-color: $base-color !important;
  }

  .border-bottom-#{$name} {
    border-bottom-color: $base-color !important;
  }

  .border-left-#{$name} {
    border-left-color: $base-color !important;
  }

  .border-right-#{$name} {
    border-right-color: $base-color !important;
  }

  /////////////////////
  // pseudo elements //
  /////////////////////

  .border-top-before-#{$name}:before,
  .border-top-after-#{$name}:after {
    border-top-color: $base-color !important;
  }

  .border-bottom-before-#{$name}:before,
  .border-bottom-after-#{$name}:after {
    border-bottom-color: $base-color !important;
  }

  .border-left-before-#{$name}:before,
  .border-left-after-#{$name}:after {
    border-left-color: $base-color !important;
  }

  .border-right-before-#{$name}:before,
  .border-right-after-#{$name}:after {
    border-right-color: $base-color !important;
  }
}

