// handling radio grid and flex
.radio-custom-grid {
    display: grid;
    grid-template-columns:1fr 1fr;
    gap:40px;
    @media (max-width: 576px)
        {
        display: flex !important;
        }
    }
.login-button-justify-content{
  justify-content: start;
  @media (max-width: 576px)
  {
  justify-content: center ;
  }
}
// handling children space grid card
.grid-children-space{
  @media (min-width: 400px) {
    &>*{
      max-width: 447.33px;
      min-width: 384px;
    }
  }
  margin-inline: -10;
  display: grid;
  grid-template-columns:auto auto auto;
  gap: 24px;
  @media (max-width: 1625px)
  {
  grid-template-columns:auto auto;
  }
  @media (max-width: 576px)
  {
  display: block !important;
  }
  @media (max-width: 384px)
  {
  display: block !important;
  max-width: 90vw;
  &>*{
  max-width: 90vw;
  }
  }
}

.grid-dashboard{
  margin-inline: -10;
  display: grid;
  grid-template-columns:3fr 1fr;
  gap: 24px;
  @media (max-width: 1625px)
  {
  grid-template-columns:2fr 1fr;
  }
  @media (max-width: 1216px)
  {
  grid-template-columns:1fr 1fr;
  }
  @media (max-width: 576px)
  {
  display: flex;
  flex-direction:column;
  gap: 24px
  }
}

.grid-rdv{
 
  display: flex;
  justify-content: space-between;
  @media (max-width: 576px)
  {
  display: flex;
  flex-direction: column;
  align-items: center;
  &>*{
    margin-bottom:10px;
    min-width: 100%;
    align-items: center;    
  }
  &>:last-child{
    margin-bottom:0px
  }
  }
  }
  
// drop down in show view
.card-edit-menu ul>li>svg>path {
  color: #757575;
}
.card-edit-menu ul>li>div {
  color: #757575;
}
// label style
label.custom-label-style {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color:'#001018'
}
label.custom-label-style > span {
    color:#E02D39;
}
// radio inout customization
input[type="radio"] {
    display: none;
  }
  input[type="radio"]+label:before {
    content: "";
    /* create custom radiobutton appearance */
    display: inline-flex;
    width: 24px;
    height: 24px;
    padding: 4px;
    margin-right: 6px;
    /* background-color only for content */
    background-clip: content-box;
    border: 2px solid #7C7C7C;
    background-color: #FFFFFF;
    border-radius: 50%;
  }
  body[dir="rtl"]{
    input[type="radio"]+label:before {
      content: "";
      margin-left: 6px;
      /* background-color only for content */
    }
  }
  /* appearance for checked radiobutton */
  input[type="radio"]:checked + label:before {
    background-color: #2de036;
  }
  /* optional styles, I'm using this for centering radiobuttons */
  label {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  iframe {
    min-width: 100% !important;
  }

  //table custom style
  div[class*="react-bootstrap-table"]{
    table{
      thead{
        background-color:#ffffff !important;
      }
      tbody{
        background-color: #F2F2F2 !important;
      }
    }
  }

  .select-filter{
    display: flex;
    justify-content: start;
    gap:16px;
    align-items: center;
    &>*{
      min-width: 316.33px
    }
    &>*[type="button"]{
      min-width: 0px;
      max-height: 40px;
      color: #FFFFFF !important;
      background-color: #A1A1A1 !important;
      border-color:  #A1A1A1 !important;
      justify-content: center;
      align-items: center;
     &:hover{
        background-color: #757575 !important;
        border-color:  #757575 !important;

      }
      &:active{
        background-color: #757575 !important;
        border-color:  #757575 !important;

      }
    }
  }


  .is-INVALID{
    border-bottom: 1px solid #E02D39;
  }

  .MuiBreadcrumbs-separator{
    color: #29366A;
    font-size: 25px;
    position: relative;
    top: -2px;
    }

    div.custom-subLabel-style{
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      color: #1B4883;
    }

    .table.table-head-custom thead th {
      color: #272729 !important;
      text-transform: none;
      font-family: 'Inter';
      font-style: normal;
      font-weight: 500;
      font-size: 0.85rem;
      line-height: 1.2rem;
    }



    div[class*="react-bootstrap-table"]{
      table{
        thead{
          background-color:#938bff !important;
          border-width: 0px 1px 1px 1px;
          border-style: solid;
          position: sticky;
          position: -webkit-sticky;
          top:0;
          border-color: #ffffff;
          th{
            text-align: center !important;
          }

        }
        tbody{
          background-color: #F4F4F4 !important;
         
            
        
          td{
            text-align: center !important;

            
          }
        }
      }
    }


    div[class*="measure-table"]{
      table{
        cursor: pointer;
        flex:1;
        thead{
          background-color:#cee02d !important;
          border-width: 0px 1px 1px 1px;
          border-style: solid;
          border-color: #cee02d;
          z-index: 1;
          th{
            text-align: center !important;
          }

        }
        tbody{
          background-color: #FFFFFF !important;
          border-width: 0px 1px 1px 1px;
          border-style: solid;
          border-color: #7C7C7C;
         
            
        
          td{
            text-align: center !important;

            
          }
        }
      }
    }

    .done{
          background-color: #B9F6D3 !important;
    }

    .notDone{
          background-color:  #FFFFFF !important;
    }

    .table.table-head-custom thead th {
      color: #010058 !important;
      text-transform: none;
      font-family:Arial, Helvetica, sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 0.85rem;
      line-height: 1.2rem;
    }



    .disabled {
      background-color: #D6D6D6 !important
    }

    .scrollable{
      max-height: 489px;
    }

    .responsive-table {
      display: flex;
      flex-direction: column;
      width: 100%;
      height:100%;
      overflow-x: auto;
      -webkit-overflow-scrolling: touch;
    }

    .outline-button{
      background-color: transparent !important;
      color: #E02D39 !important;
      border-color: #E02D39 !important;
      font-weight: 600;
    }

    $gaps: (6, 8 , 12,16, 18, 24, 32,40, 48);
    @each $gap in $gaps {
      .gap-#{$gap} {
        gap: #{$gap}px;
        @media (max-width: 576px)
        {
        gap: 0px;
        }
      }
    }
  
    $gaps: (6, 8 , 12, 16, 18, 24, 32,40, 48);
    @each $gap in $gaps {
      .gaps-#{$gap} {
        gap: #{$gap}px;
      }
    }

    .svg-icon.svg-icon-darkBlue svg g [fill] {
      transition: fill 0.3s ease;
      fill: #1B4883 !important;
  }

    $flex: 4;
    @for $i from 1 through $flex{
    .flex-#{$i}{
      flex:$i;
      @media (max-width: 576px)
           {
           flex: 0;
           }
       }
      }

      $fontSize: 24;
      @for $i from 1 through $fontSize{
        .fontSize-#{$i}{
          font-size: #{$i}px;
          @media (max-width: 576px)
          {
            font-size: 16px;
    
          }
    
        }
      }

      .Work-Sans{
        font-family: 'Work Sans', sans-serif;
      }


      .sub-title{
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 600;
        font-size: 1.125rem;
        line-height: 1.75rem;
        color: #303030;
      }

      // .shadow{
      //   -webkit-filter: drop-shadow( 4px 4px 2px rgba(0, 0, 0, 0.5));
      //   filter: drop-shadow( 4px 4px 2px rgba(0, 0, 0, 0.5));
        
      // }

      .flip-Position{
        margin-top: -295px !important;
      }



      ///download button 
      
      .button {
        -moz-appearance: none;
        -webkit-appearance: none;
        appearance: none;
        border: none;
        background: none;
        color: #0f1923;
        cursor: pointer;
        position: relative;
        padding: 8px;
        margin-bottom: 20px;
        text-transform: uppercase;
        font-weight: bold;
        font-size: 14px;
        transition: all .15s ease;
      }
      
      .button::before,
      .button::after {
        content: '';
        display: block;
        position: absolute;
        right: 0;
        left: 0;
        height: calc(50% - 5px);
        border: 1px solid #7D8082;
        transition: all .15s ease;
      }
      
      .button::before {
        top: 0;
        border-bottom-width: 0;
      }
      
      .button::after {
        bottom: 0;
        border-top-width: 0;
      }
      
      .button:active,
      .button:focus {
        outline: none;
      }
      
      .button:active::before,
      .button:active::after {
        right: 3px;
        left: 3px;
      }
      
      .button:active::before {
        top: 3px;
      }
      
      .button:active::after {
        bottom: 3px;
      }
      
      .button_lg {
        position: relative;
        display: block;
        padding: 10px 20px;
        color: #fff;
        background-color: #0f1923;
        overflow: hidden;
        box-shadow: inset 0px 0px 0px 1px transparent;
      }
      
      .button_lg::before {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 2px;
        height: 2px;
        background-color: #0f1923;
      }
      
      .button_lg::after {
        content: '';
        display: block;
        position: absolute;
        right: 0;
        bottom: 0;
        width: 4px;
        height: 4px;
        background-color: #0f1923;
        transition: all .2s ease;
      }
      
      .button_sl {
        display: block;
        position: absolute;
        top: 0;
        bottom: -1px;
        left: -8px;
        width: 0;
        background-color: #ff4655;
        transform: skew(-15deg);
        transition: all .2s ease;
      }
      
      .button_text {
        position: relative;
      }
      
      .button:hover {
        color: #0f1923;
      }
      
      .button:hover .button_sl {
        width: calc(100% + 15px);
      }
      
      .button:hover .button_lg::after {
        background-color: #fff;
      }
      


    
.glow {
  position: relative;
  color:#E02D39;
  font-weight: bold;
  letter-spacing: 1px;
  text-align: center;
  outline: none;
  animation: animate  3s linear infinite 

}

@keyframes animate 
{
  0%,50.1%,80%,92%
  {
    color:black;
    box-shadow: none;
    opacity: 0.5;
  }
  18.1%,50%,80.1%,92.1%
  {
    color:#E02D39;
    opacity: 1;
  }
}

.medical-folder-grid{
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(4, 0.2fr);
  gap:1rem;
  @media (max-width: 810px)
        {
        grid-template-columns: 1fr;
        grid-template-rows: 1fr;
        row-gap: 1rem;
        }
}

.folder-card-header{
  grid-area: 1 / 1 / 2 / 3;
  @media (max-width: 810px)
  {
    grid-area: auto;
  }
}
.growth-table-title{
  grid-area: 2 / 1 / 3 / 3;
  @media (max-width: 810px)
  {
    grid-area: auto;
  }
}
.data-table-growth{
  grid-area: 3 / 1 / 5 / 2;
  @media (max-width: 810px)
  {
    grid-area: auto;
  }
}

.growth-table-button{
  grid-area: 5 / 1 / 6 / 3;
  @media (max-width: 810px)
  {
    grid-area: auto;

  }
}

.mapi-card{
  grid-area: 3 / 2 / 4 / 3;
  @media (max-width: 810px)
  {
    grid-area: auto;
  }
}

.allergy-card{
  grid-area: 4 / 2 / 5 / 3;
  @media (max-width: 810px)
  {
    grid-area: auto;

  }
}
           
.growth-graph-title{
  grid-area: 6 / 1 / 7 / 3;
  @media (max-width: 810px)
  {
    grid-area: auto;
  }
}

.growth-graph{
  grid-area: 7 / 1 / 8 / 3;
  @media (max-width: 810px)
  {
    grid-area: auto;
  }
}

.head-circumference-table-title{
  grid-area: 8 / 1 / 9 / 3;
  @media (max-width: 810px)
  {
    grid-area: auto;
  }
}
.data-table-head-circumference{
  grid-area: 9 / 1 / 10 / 3;
  @media (max-width: 810px)
  {
    grid-area: auto;
  }
}

.head-circumference-table-button{
  grid-area: 10 / 1 / 11 / 3;
  @media (max-width: 810px)
  {
    grid-area: auto;

  }
}

.modal-dialog.modal-mapi {
  max-width : 80vw !important; 
}
.fixed-paragraph {
  height: 100px; /* Ajustez selon vos besoins */
  overflow: hidden; /* Masque le débordement */
  text-overflow: ellipsis; /* Ajoute des points de suspension (...) pour le texte coupé */
  display: -webkit-box; /* Nécessaire pour l'utilisation de -webkit-line-clamp */
  -webkit-line-clamp: 3; /* Limite le texte à 3 lignes */
  -webkit-box-orient: vertical; /* Nécessaire pour l'utilisation de -webkit-line-clamp */
}
.cards1 {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.cards1 .red {
  background-color: #f43f5e;
}

.cards1 .blue {
  background-color: #3b82f6;
}

.cards1 .green {
  background-color: #22c55e;
}

.cards1 .card {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  height: 180px;
  width: 410px;
  border-radius: 10px;
  color: white;
  cursor: pointer;
  transition: 400ms;
  padding: 10px;
  margin: 10px;
}

.cards1 .card p.tip {
  font-size: 1em;
  font-weight: 700;
}

.cards1 .card p.second-text {
  font-size: .7em;
}
.cards1 .card p.third-text {
  font-size: .7em;
  color: red;
}

.cards1 .card:hover {
  transform: scale(1.1, 1.1);
}

.cards1:hover > .card:not(:hover) {
  filter: blur(10px);
  transform: scale(0.9, 0.9);
}
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background: white;
  padding: 20px;
  border-radius: 5px;
  max-width: 700px;
  width: 100%;
  max-height: calc(100% - 20px); /* Adjust to accommodate the close button */
  overflow-y: auto; /* Enable vertical scrollbar */
  position: relative;
}

.modal-close {
  position: absolute;
  top: 3px;
  right: 3px;
  background: none;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
}

.modal-inner-content {
  margin-bottom: 20px; /* Add space between inner content and close button */
}
