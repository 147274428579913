//
// Error 3
//

// Initialization of global variables, mixins and functions
@import "../../init";

.error {
  .error-text {
    padding-bottom: 5rem !important;
  }
}

.error {
  min-height: 100%;
}

@include media-breakpoint-up(md) {
  .error {
    .error-text {
      padding-top: 3rem !important;
    }
  }
}
