//
// Bootstrap Datetimepicker
//

.SelectedWeekExample .DayPicker {
  width: 100%;
}
.SelectedWeekExample .DayPicker-Caption {
  text-transform: capitalize;
}
.SelectedWeekExample .DayPicker-Month {
  border-collapse: separate;
  width: 100%;
}
.SelectedWeekExample .DayPicker-WeekNumber {
  outline: none;
}
.SelectedWeekExample .DayPicker-Day {
  outline: none;
  border: 1px solid transparent;
}
.SelectedWeekExample .DayPicker-Day--hoverRange {
  background-color: #EFEFEF !important;
}

.SelectedWeekExample .DayPicker-Day--selectedRange {
  background-color: #fff7ba !important;
  border-top-color: #FFEB3B;
  border-bottom-color: #FFEB3B;
  border-left-color: #fff7ba;
  border-right-color: #fff7ba;
}

.SelectedWeekExample .DayPicker-Day--selectedRangeStart {
  background-color: #FFEB3B !important;
  border-left: 1px solid #FFEB3B;
}

.SelectedWeekExample .DayPicker-Day--selectedRangeEnd {
  background-color: #FFEB3B !important;
  border-right: 1px solid #FFEB3B;
}

.SelectedWeekExample .DayPicker-Day--selectedRange:not(.DayPicker-Day--outside).DayPicker-Day--selected,
.SelectedWeekExample .DayPicker-Day--hoverRange:not(.DayPicker-Day--outside).DayPicker-Day--selected {
  border-radius: 0 !important;
  color: black !important;
}
.SelectedWeekExample .DayPicker-Day--hoverRange:hover {
  border-radius: 0 !important;
}

.SelectedWeekExample .DayPicker-Day {
  border-radius: 0 !important;
}
// Base
.datepicker {
  width: 265px;
  padding: 10px;
  @include border-radius($border-radius);

  &.datepicker-orient-top {
    margin-top: 8px;
  }

  table {
    width: 100%;
  }

  td,
  th {
    font-size: 1rem;
    font-weight: regular;
    width: 33px;
    height: 33px;
    @include border-radius($border-radius);
  }

  thead {
    th {
      color: $dark-75;
      &.prev,
      &.datepicker-switch,
      &.next {
        font-weight: 500;
        color: $dark-75;

        i {
          font-size: 1.2rem;
          color: $dark-50;

          &:before {
            line-height: 0;
            vertical-align: middle;
          }
        }

        &:hover {
          background: $gray-100 !important;
        }
      }

      &.dow {
        color: $dark-75;
        font-weight: 600;
      }
    }
  }

  tbody {
    tr > td {
      width: 35px;
      height: 35px;

      &.day {
        color: $dark-50;
        font-weight: 400;

        &:hover {
          background: $gray-100;
          color: $dark-75;
        }

        &.old {
          color: $dark-50;
        }

        &.new {
          color: $dark-75;
        }

        &.selected,
        &.selected:hover,
        &.active,
        &.active:hover {
          background: $primary;
          color: $white;
        }

        &.today {
          position: relative;
          background: $primary-light !important;
          color: $primary !important;

          &:before {
            content: '';
            display: inline-block;
            border: solid transparent;
            border-width: 0 0 7px 7px;
            border-bottom-color: $primary;
            border-top-color: $primary;
            position: absolute;
            bottom: 4px;
            right: 4px;
          }
        }

        &.range {
          background: $gray-100;
        }
      }

      span.year,
      span.hour,
      span.minute,
      span.month {
        color: $dark-50;

        &:hover {
          background: $gray-100;
        }

        &.focused,
        &.focused:hover,
        &.active:hover,
        &.active.focused:hover,
        &.active {
          background: $primary;
          color: $white;
        }
      }
    }
  }

  tfoot {
    tr > th {
      width: 35px;
      height: 35px;

      &.today,
      &.clear {
        @include border-radius($border-radius);
        font-weight: 500;

        &:hover {
          background: $gray-200;
        }
      }
    }
  }

  &.datepicker-inline {
    border: 1px solid $gray-200;
  }
}

.input-daterange {
  .input-group-addon {
    min-width: 44px;
  }

  input {
    text-align: left;
  }

  .input-group-append {
    .input-group-text {
      border-right: 0;
    }
  }
}
