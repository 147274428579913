//
// Wizard 4
// Pages SASS files are compiled into separate css files
//

// Initialization of global variables, mixins and functions
@import "../../init";

// Base
.wizard.wizard-4 {
	flex-direction: column;

	// Nav
	.wizard-nav {
		// Steps
		.wizard-steps {
			display: flex;
      align-items: stretch;
			justify-content: space-between;
			flex-wrap: wrap;
      cursor: pointer;

			// Step
			.wizard-step {
				display: flex;
				align-items: center;
				justify-content: center;
				flex-wrap: wrap;
				flex: 0 0 calc(25% - 0.25rem);
				width: calc(25% - 0.25rem);
				background-color: $gray-100;
				border-top-left-radius: 0.5rem;
				border-top-right-radius: 0.5rem;

				.wizard-wrapper {
					flex: 1;
					display: flex;
					align-items: center;
					flex-wrap: nowrap;
					color: $dark-75;
					padding: 2rem 2.5rem;

					.wizard-number {
						font-size: 1.3rem;
						font-weight: 600;
						flex: 0 0 2.75rem;
						height: 2.75rem;
						width: 2.75rem;
						display: flex;
						align-items: center;
						justify-content: center;
						background-color: rgba($primary, 0.08);
						color: $primary;
						margin-right: 1rem;
						border-radius: 0.5rem;
					}

					.wizard-label {
						display: flex;
						flex-direction: column;

						.wizard-title {
							font-size: 1.1rem;
							font-weight: 600;
						}
					}
				}

				&[data-wizard-state="current"] {
          color: $white;
          background-color: $primary;

					.wizard-wrapper {
						.wizard-number {
						  color: $white;
              background-color: rgba(255, 255, 255, 0.3);
						}

						.wizard-label {
							.wizard-title {
								color: $white;
							}
						}
					}
				}

        &[data-wizard-state="done"] {

					.wizard-wrapper {
						.wizard-number {
						  color: $success;
						}

						.wizard-label {
							.wizard-title {
								color: $success;
							}
						}
					}
				}
			}

			@for $i from 2 through 4 {
				$width: 100 / $i;
				&[data-total-steps="#{$i}"] {
					.wizard-step {
						flex: 0 0 calc(#{$width}% - 0.25rem);
						width: calc(#{$width}% - 0.25rem);
					}
				}
			}
		}
	}
}

// Small Desktop Mode
@include media-breakpoint-down(xl) {
	.wizard.wizard-4 {
		// Nav
		.wizard-nav {
			.wizard-steps {
				.wizard-step {
					flex: 0 0 calc(50% - 0.25rem);
					width: calc(50% - 0.25rem);
					border-bottom-left-radius: 0.5rem;
					border-bottom-right-radius: 0.5rem;
					margin-bottom: 0.5rem;
				}

				&[data-total-steps="2"],
				&[data-total-steps="4"] {
					.wizard-step {
						flex: 0 0 calc(50% - 0.25rem);
						width: calc(50% - 0.25rem);
					}
				}

				&[data-total-steps="3"] {
					.wizard-step {
						flex: 0 0 100%;
						width: 100%;
					}
				}
			}
		}
	}
}

// Mobile mode
@include media-breakpoint-down(sm) {
	.wizard.wizard-4 {
		// Nav
		.wizard-nav {
			.wizard-steps {
				flex-direction: column;
				align-items: flex-start;

				.wizard-step {
					flex: 0 0 100% !important;
					position: relative;
					width: 100% !important;

					.wizard-wrapper {
						justify-content: flex-start;
						flex: 0 0 100%;
						padding: 0.5rem 2rem;
					}
				}
			}
		}
	}
}


// Base
.wizard.wizard-2 {
	flex-direction: column;

	// Nav
	.wizard-nav {
		// Steps
		.wizard-steps {
			display: flex;
      align-items: stretch;
			justify-content: space-between;
			flex-wrap: wrap;
      cursor: pointer;

			// Step
			.wizard-step {
				display: flex;
				align-items: center;
				justify-content: center;
				flex-wrap: wrap;
				flex: 0 0 calc(50% - 0.25rem);
				width: calc(50% - 0.25rem);
				background-color: $gray-100;
				border-top-left-radius: 0.5rem;
				border-top-right-radius: 0.5rem;

				.wizard-wrapper {
					flex: 1;
					display: flex;
					align-items: center;
					flex-wrap: nowrap;
					color: $dark-75;
					padding: 2rem 2.5rem;

					.wizard-number {
						font-size: 1.3rem;
						font-weight: 600;
						flex: 0 0 2.75rem;
						height: 2.75rem;
						width: 2.75rem;
						display: flex;
						align-items: center;
						justify-content: center;
						background-color: rgba($primary, 0.08);
						color: $primary;
						margin-right: 1rem;
						border-radius: 0.5rem;
					}

					.wizard-label {
						display: flex;
						flex-direction: column;

						.wizard-title {
							font-size: 1.1rem;
							font-weight: 600;
						}
					}
				}

				&[data-wizard-state="current"] {
          color: $white;
          background-color: $primary;

					.wizard-wrapper {
						.wizard-number {
						  color: $white;
              background-color: rgba(255, 255, 255, 0.3);
						}

						.wizard-label {
							.wizard-title {
								color: $white;
							}
						}
					}
				}

        &[data-wizard-state="done"] {

					.wizard-wrapper {
						.wizard-number {
						  color: $success;
						}

						.wizard-label {
							.wizard-title {
								color: $success;
							}
						}
					}
				}
			}

			@for $i from 2 through 4 {
				$width: 100 / $i;
				&[data-total-steps="#{$i}"] {
					.wizard-step {
						flex: 0 0 calc(#{$width}% - 0.25rem);
						width: calc(#{$width}% - 0.25rem);
					}
				}
			}
		}
	}
}

// Small Desktop Mode
@include media-breakpoint-down(xl) {
	.wizard.wizard-2 {
		// Nav
		.wizard-nav {
			.wizard-steps {
				.wizard-step {
					flex: 0 0 calc(50% - 0.25rem);
					width: calc(50% - 0.25rem);
					border-bottom-left-radius: 0.5rem;
					border-bottom-right-radius: 0.5rem;
					margin-bottom: 0.5rem;
				}

				&[data-total-steps="2"],
				&[data-total-steps="4"] {
					.wizard-step {
						flex: 0 0 calc(50% - 0.25rem);
						width: calc(50% - 0.25rem);
					}
				}

				&[data-total-steps="3"] {
					.wizard-step {
						flex: 0 0 100%;
						width: 100%;
					}
				}
			}
		}
	}
}

// Mobile mode
@include media-breakpoint-down(sm) {
	.wizard.wizard-2 {
		// Nav
		.wizard-nav {
			.wizard-steps {
				flex-direction: column;
				align-items: flex-start;

				.wizard-step {
					flex: 0 0 100% !important;
					position: relative;
					width: 100% !important;

					.wizard-wrapper {
						justify-content: flex-start;
						flex: 0 0 100%;
						padding: 0.5rem 2rem;
					}
				}
			}
		}
	}
}


// Base
.wizard.wizard-3 {
	flex-direction: column;

	// Nav
	.wizard-nav {
		// Steps
		.wizard-steps {
			display: flex;
      align-items: stretch;
			justify-content: space-between;
			flex-wrap: wrap;
      cursor: pointer;

			// Step
			.wizard-step {
				display: flex;
				align-items: center;
				justify-content: center;
				flex-wrap: wrap;
				flex: 0 0 calc(33.35% - 0.25rem);
				width: calc(33.35% - 0.25rem);
				background-color: $gray-100;
				border-top-left-radius: 0.5rem;
				border-top-right-radius: 0.5rem;

				.wizard-wrapper {
					flex: 1;
					display: flex;
					align-items: center;
					flex-wrap: nowrap;
					color: $dark-75;
					padding: 2rem 2.5rem;

					.wizard-number {
						font-size: 1.3rem;
						font-weight: 600;
						flex: 0 0 2.75rem;
						height: 2.75rem;
						width: 2.75rem;
						display: flex;
						align-items: center;
						justify-content: center;
						background-color: rgba($primary, 0.08);
						color: $primary;
						margin-right: 1rem;
						border-radius: 0.5rem;
					}

					.wizard-label {
						display: flex;
						flex-direction: column;

						.wizard-title {
							font-size: 1.1rem;
							font-weight: 600;
						}
					}
				}

				&[data-wizard-state="current"] {
          color: $white;
          background-color: $primary;

					.wizard-wrapper {
						.wizard-number {
						  color: $white;
              background-color: rgba(255, 255, 255, 0.3);
						}

						.wizard-label {
							.wizard-title {
								color: $white;
							}
						}
					}
				}

        &[data-wizard-state="done"] {

					.wizard-wrapper {
						.wizard-number {
						  color: $success;
						}

						.wizard-label {
							.wizard-title {
								color: $success;
							}
						}
					}
				}
			}

			@for $i from 1 through 3 {
				$width: 100 / $i;
				&[data-total-steps="#{$i}"] {
					.wizard-step {
						flex: 0 0 calc(#{$width}% - 0.25rem);
						width: calc(#{$width}% - 0.25rem);
					}
				}
			}
		}
	}
}

// Small Desktop Mode
@include media-breakpoint-down(xl) {
	.wizard.wizard-3 {
		// Nav
		.wizard-nav {
			.wizard-steps {
				.wizard-step {
					flex: 0 0 calc(50% - 0.25rem);
					width: calc(50% - 0.25rem);
					border-bottom-left-radius: 0.5rem;
					border-bottom-right-radius: 0.5rem;
					margin-bottom: 0.5rem;
				}

				&[data-total-steps="2"],
				&[data-total-steps="4"] {
					.wizard-step {
						flex: 0 0 calc(50% - 0.25rem);
						width: calc(50% - 0.25rem);
					}
				}

				&[data-total-steps="3"] {
					.wizard-step {
						flex: 0 0 100%;
						width: 100%;
					}
				}
			}
		}
	}
}

// Mobile mode
@include media-breakpoint-down(sm) {
	.wizard.wizard-3 {
		// Nav
		.wizard-nav {
			.wizard-steps {
				flex-direction: column;
				align-items: flex-start;

				.wizard-step {
					flex: 0 0 100% !important;
					position: relative;
					width: 100% !important;

					.wizard-wrapper {
						justify-content: flex-start;
						flex: 0 0 100%;
						padding: 0.5rem 2rem;
					}
				}
			}
		}
	}
}
