

.img-cropper {
  height: 300px;
    width: 100%;
}

.img-preview {
  height: 250px;
  width: 100%;
  overflow: hidden;
}

.cropper-preview {
  height: 250px;
}
