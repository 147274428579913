//
// Brand
//

.brand {
	display: flex;
	justify-content: space-between;
	align-items: center;
	flex-direction: row;
	padding: 0px 61px 0px 21px ;
	height: get($header-config, desktop, default, height);

	// Logo
	.brand-logo {
		display: flex;
		justify-content: flex-begin;

		// Minimized Aside Mode
		.aside-minimize & {
			img {
				object-fit: cover;
  				width: 60px;
  				height: 60px;
				object-position: 0% 0%;
			}
		}
	}

	// Toggle Icon
	.brand-toggle {
		left:336px;
		position: absolute;
		.svg-icon {
			svg {
				transition: $transition;
				
			}
		}
	}
}

// Desktop Mode
@include media-breakpoint-up(lg) {
	.brand {
		// Fixed Header & Static Aside Mode
		.header-fixed.aside-static & {
			position: fixed;
			top: 0;
			left: 0;
			width: get($aside-config, base, width);
			z-index: get($header-config, desktop, fixed, zindex);
		}

		// Fixed Header & Aside Minimize Modes
		.aside-minimize & {
			justify-content: center;

			// Toggle Icon
			.brand-toggle {
				left:80px;
				position: absolute;
				.svg-icon {
					svg {
						transition: $transition;
						transform: rotate(180deg);
						/*rtl:begin:*/
						//transform: rotate(-180deg);
						/*rtl:end:*/
					}
				}
			}
		}

		// Aside Minimize & Fixed Header & Static Aside Mode
		.header-fixed.aside-static.aside-minimize & {
			width: get($aside-config, base, width);
		}
	}
}

// Tablet & Mobile Modes
@include media-breakpoint-down(md) {
	.brand {
		display: none;
	}
}



body[dir*="rtl"]{
	.brand {
		.brand-toggle {
			right:336px;
		}
	}
}

body.aside-minimize[dir*="rtl"]{
	.brand {
		.brand-toggle {
			right:80px;
		}
	}
}


	

body[dir*="rtl"]{
	.brand {
		padding: 0px 21px 0px 61px ;
	}
}